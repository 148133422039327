import { useRef, useState } from "react";
import copy from "copy-to-clipboard";
import errorImage from "../assets/error.png";
import { PrimaryBtn } from "./Buttons";

type Props = {
  message: string;
};

const ErrorWrapper = ({ message }: Props) => {
  const textRef = useRef<HTMLInputElement | null>(null);
  const [buttonText, setButtonText] = useState("Copy Message");

  const copyToClipboard = () => {
    if (textRef && textRef.current) {
      let copyText = textRef.current.value;
      copy(copyText);
      setButtonText("Copied!");
    }
  };

  return (
    <div className="grid place-items-center w-[75%] mx-auto">
      <div className="flex flex-col gap-6">
        <img className="w-96 h-96 self-center" src={errorImage} />
        <div className="text-7xl font-bold">Ooops! We have an error...</div>
        <div className="text-3xl ">
          Please{" "}
          <a
            className="text-primary-100 underline"
            href="  https://kptncook.slack.com/channels/cms"
          >
            write on the #CMS channel in Slack
          </a>
        </div>
        <div className="relative">
          <code className="text-sm sm:text-base inline-flex text-left items-center space-x-4 bg-gray-800 text-white rounded-lg p-4 w-full -z-10 ">{`Error: "${message}"`}</code>
          <input value={message} hidden disabled type="text" ref={textRef} />
          <PrimaryBtn
            className="w-full mt-2"
            text={buttonText}
            onClick={copyToClipboard}
          />
          <a
            href="/recipes"
            className="w-full mt-2 basic-transition flex min-w-[180px] items-center justify-center rounded border border-solid border-primary-100 bg-transparent p-[7px] font-Alianza700 uppercase tracking-wider text-primary-100 hover:bg-primary-100 hover:text-bgColor"
          >
            Return Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorWrapper;
